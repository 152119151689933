
import { defineComponent } from 'vue'

export default defineComponent({
  components: {},
  setup() {
    const onSuccess = value => alert('已複製 ' + value)
    return { onSuccess }
  }
})
